<template>
  <v-row>
    <!-- <v-col
      cols="12"
      md="4"
    >
      <dashboard-congratulation-john></dashboard-congratulation-john>
    </v-col> -->
    <v-col
      cols="12"
      md="8"
    >
      <dashboard-statistics-card></dashboard-statistics-card>
    </v-col>

    <!-- <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <dashboard-weekly-overview></dashboard-weekly-overview>
    </v-col> -->

    <v-col
      cols="12"
      md="4"
      sm="6"
    >
      <dashboard-card-total-earning v-if="this.$store.getters.usuario.user_rol === 'Gerente' || this.$store.getters.usuario.user_rol === 'Encargado'"></dashboard-card-total-earning>
    </v-col>
    <impago-modal ref="impagoModal"></impago-modal>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

// demos
// import DashboardCongratulationJohn from './DashboardCongratulationJohn.vue'
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'

import DashboardCardTotalEarning from './DashboardCardTotalEarning.vue'
import ImpagoModal from './ModalImpago.vue'
// import DashboardCardDepositAndWithdraw from './DashboardCardDepositAndWithdraw.vue'
// import DashboardCardSalesByCountries from './DashboardCardSalesByCountries.vue'
// import DashboardWeeklyOverview from './DashboardWeeklyOverview.vue'
// import DashboardDatatable from './DashboardDatatable.vue'

export default {
  components: {
    // StatisticsCardVertical,
    // DashboardCongratulationJohn,
    DashboardStatisticsCard,

    DashboardCardTotalEarning,
    ImpagoModal,

    // DashboardCardDepositAndWithdraw,
    // DashboardCardSalesByCountries,
    // DashboardWeeklyOverview,
    // DashboardDatatable,
  },
  setup() {
    const totalProfit = {
      statTitle: 'Total Profit',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Weekly Project',
      statistics: '$25.6k',
      change: '+42%',
    }

    const totalSales = {
      statTitle: 'Refunds',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Past Month',
      statistics: '$78',
      change: '-15%',
    }

    // vertical card options
    const newProject = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    const salesQueries = {
      statTitle: 'Sales Quries',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Last week',
      statistics: '15',
      change: '-18%',
    }

    return {
      totalProfit,
      totalSales,
      newProject,
      salesQueries,
    }
  },
  mounted() {
    console.log(this.$store.getters.usuario.user_rol)
    // if(this.$store.getters.usuario.user_rol == 'Encargado' || this.$store.getters.usuario.user_rol == 'Administrador' || this.$store.getters.usuario.user_rol == 'Gerente') {
    //   this.$refs.impagoModal.openDialog()
    // }
  },
  computed: {
    // SesionActiva() {
    //   if (
    //     this.$store.getters.usuario.expiration
    //     && this.moment().isBefore(
    //       this.moment(this.$store.getters.usuario.expiration),
    //       'minute',
    //     )
    //   ) {
    //     return true
    //   }
    //   console.log(this.$store.getters.usuario)
    //   this.cerrarSesion()

    //   return false
    // },
  },
  methods: {
    // cerrarSesion() {
    //   this.$store.dispatch('agregarUsuarioAction', {})
    //   this.$router.push('/')
    // },
  },
}
</script>
